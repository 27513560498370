<template>
  <v-list-item>
    <v-list-item-title>
      <addField
        :path="currentTable.tableUrl"
        :additionalAttributes="additionalAttributes"
        :showDialog="openDialog"
        @dialog-closed="openDialog=false"
      >
        <v-card class="pa-2 mt-3 elevation-0">
          <v-icon class="mr-2 mt-n1">mdi-chart-timeline</v-icon>{{language.componentLabel.labelShowMSC}}
        </v-card>
      </addField>
    </v-list-item-title>
  </v-list-item>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
export default {
  data() {
    return { openDialog: false };
  },
  // this.$emit("close-menu");
  components: { addField },
  props: ["currentTable", "selectedRows", "row"],
  computed: {
    additionalAttributes() {
      return {
        function: "getCloudURL",
        n_id: this.row.n_id,
        n_id_key: this.row.n_id_key,
        n_dataset_version: this.row.n_dataset_version_data,
      };
    },
  },
};

// function showSequenceDiagram(that, n_id, n_id_key) {
//   var sourceSD = $(that).closest("tr").find(".sequenceDiagram");
//   var sequenceDiagram = sourceSD.text();
//   sequenceDiagram = sequenceDiagram.trim();
//   showLoading();
//   let timeDelay = 3000;
//   if (sequenceDiagram == 1) {
//     //recently saved if it 1 then old no need new image to show time delay 0
//     timeDelay = 0;
//   }
//   setTimeout(function () {
//     executeGetDiagram(that, n_id, n_id_key, sequenceDiagram, sourceSD);
//   }, timeDelay);
// }

// function executeGetDiagram(that, n_id, n_id_key, sequenceDiagram, sourceSD) {
//   $.ajax({
//     type: "POST",
//     data: { function: "getCloudURL", n_id: n_id, n_id_key: n_id_key },
//     dataType: "json",
//     success: function (data) {
//       if (data == false) {
//         showSimpleNotice(
//           "The Message Sequence Chart is being created...",
//           300,
//           "Notice"
//         );
//       } else if (sequenceDiagram == 2) {
//         showSimpleNotice(
//           "A problem has occurred while creating the message Sequence Chart.",
//           300,
//           "Notice"
//         );
//       } else {
//         var caller =
//           '<span  data-showType="image" data-popup="<image src=' +
//           data +
//           '/>">';
//         getDataTableDialog(caller, "", "", {
//           width: 850,
//           height: 450,
//           title: "Message Sequence Chart",
//         });
//         sourceSD.html(1); //update the SD as 1 its showed and no need delay 3 sec when it click again
//       }
//       hideLoading();
//     },
//   });
// }
</script>